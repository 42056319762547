import { create, StateCreator } from "zustand";
import { devtools } from "zustand/middleware";
import { inDevTestLocal } from "@utils/inDevTestLocal";

export enum AuthenticationStatus {
  WAITING_FOR_STATUS = "waiting_for_auth_status",
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated",
  CLAIMS_PENDING = "claims_pending",
}

type AuthenticationStatusStore = {
  status: AuthenticationStatus;
  setStatus: (status: AuthenticationStatus) => void;
};

// This store is being used to enable `useTokenRefresh` hooks to work on different levels of the application
// without re-validating authentication every time
const authenticationStatusStore: StateCreator<
  AuthenticationStatusStore,
  [["zustand/devtools", AuthenticationStatusStore]]
> = (set) => ({
  status: AuthenticationStatus.WAITING_FOR_STATUS,
  setStatus: (status) => set({ status }),
});

const useAuthenticationStatusStore = create<AuthenticationStatusStore>()(
  devtools(authenticationStatusStore, { enabled: inDevTestLocal, name: "authenticationStore" }),
);

export default useAuthenticationStatusStore;
