import { useCallback, useRef } from "react";
import axios, { AxiosError } from "axios";

import useAuthStore from "@stores/authStore";
import { authorize as authorizeService, lookupUser, verify } from "@services/auth";
import { ApiResponse, AuthorizeResponse, LookupUserResponse } from "@models/api/apiResponses";
import skipifyEvents from "@services/skipifyEvents";
import useOrderStore from "@stores/orderStore";

export enum ChallengeStatus {
  COMPLETED,
  FAILED,
  ERROR,
  TOO_MANY_ATTEMPTS,
  NOT_FOUND,
}

const useAuth = ({
  onChallengeAccepted,
  onChallengeCompleted,
  merchantId,
}: {
  onChallengeAccepted?: (a?: AuthorizeResponse) => void;
  onChallengeCompleted?: () => void;
  acceptChallengeWhenUserRecognized?: boolean;
  merchantId?: string;
} = {}) => {
  const {
    authorize,
    reAuthorize,
    lookup,
    transactionId,
    isPhoneRequired,
    resetIsPhoneRequired,
    resetChallenge,
    setEnrollmentChallengeInitialization,
    setPartialEnrollmentData,
    setAttemptsRemaining,
    destination,
  } = useAuthStore();

  const lookupAbortController = useRef<AbortController>();
  const [orderAuthorizationError, clearAuthorizationError] = useOrderStore((state) => [
    state.fetchOrderError,
    state.clearAuthorizationError,
  ]);

  // Make an /authorize call to proceed with a challenge to OTP/PUSH/whatever
  const authorizeUser = useCallback(async () => {
    try {
      // If authorization is okay, we can assume the OTP is sent, maskedChannel will be added to store
      // we can move to auth page.
      const authorizeResponse = await authorize();
      if (authorizeResponse) {
        if (onChallengeAccepted) {
          onChallengeAccepted(authorizeResponse);
        }
      }
      return authorizeResponse;
    } catch (e) {
      console.warn("[useAuth] error during authorize", e);
    }
  }, [authorize, onChallengeAccepted]);

  const reAcceptChallenge = useCallback(async () => {
    skipifyEvents.track("fe_otp_resend_code");
    const resp = await reAuthorize();
    skipifyEvents.track("fe_otp_sent");
    return resp;
  }, [reAuthorize]);

  // Continue challenge with a lookup call to send a user's phone
  // And proceed the challenge
  const acceptChallengeWithPhone = useCallback(
    async (phone: string, skipCardLinking?: boolean, updatedTransactionId?: string) => {
      // Do not proceed with the challenge if it hasn't been initialized
      if (transactionId || updatedTransactionId) {
        // No abort signal here - user should not be able to abort this call by changing an email/phone
        await lookup(merchantId as string, undefined, phone, undefined, skipCardLinking);
        return await authorizeUser();
      }
    },
    [authorizeUser, lookup, merchantId, transactionId],
  );

  const completeChallenge = useCallback(
    async (otp: string): Promise<ChallengeStatus> => {
      try {
        skipifyEvents.track("fe_otp_submitted");
        if (!transactionId) {
          return ChallengeStatus.NOT_FOUND;
        }
        const otpResponse = await verify(transactionId, otp);
        if (otpResponse.code === 200) {
          setAttemptsRemaining(null);

          // A user may have a 401 for the order by trying to get a claimed order. We allow them to authorize and complete a challenge to check if they are the user who claimed the order.
          if (orderAuthorizationError && orderAuthorizationError.response?.status === 401) {
            clearAuthorizationError();
          }

          onChallengeCompleted?.();

          if (isPhoneRequired) {
            skipifyEvents.track("fe_customer_account_created");
          }

          skipifyEvents.track("fe_otp_success");
          skipifyEvents.track("fe_auth", { status: "Success", auth_method: `OTP_${destination?.toUpperCase()}` });

          return ChallengeStatus.COMPLETED;
        } else {
          skipifyEvents.track("fe_otp_fail");
          return ChallengeStatus.FAILED;
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          //If response is 429 need to display too many wrong attempts
          if (e?.response?.status === 429) {
            skipifyEvents.track("fe_otp_fail");
            return ChallengeStatus.TOO_MANY_ATTEMPTS;
          }

          if (e?.response?.status === 404) {
            return ChallengeStatus.NOT_FOUND;
          }

          if (e?.response?.status === 422) {
            if (e.response?.data?.data?.remainingAttempts) {
              setAttemptsRemaining(e.response?.data?.data?.remainingAttempts);
            }
            skipifyEvents.track("fe_otp_fail");
          }
          skipifyEvents.track("fe_auth", { status: "Fail", auth_method: `OTP_${destination?.toUpperCase()}` });
        }

        return ChallengeStatus.ERROR;
      }
    },
    [
      clearAuthorizationError,
      destination,
      isPhoneRequired,
      onChallengeCompleted,
      orderAuthorizationError,
      setAttemptsRemaining,
      transactionId,
    ],
  );

  const abortLookupUserByEmail = useCallback(() => {
    if (lookupAbortController.current) {
      lookupAbortController.current.abort();
      lookupAbortController.current = undefined;
    }
  }, []);

  // Initialize/continue a challenge by looking for a user by email
  const lookupUserByEmail = useCallback(
    async (email: string, skipCardLinking?: boolean) => {
      abortLookupUserByEmail();
      lookupAbortController.current = new AbortController();
      return await lookup(merchantId as string, email, undefined, lookupAbortController.current, skipCardLinking);
    },
    [abortLookupUserByEmail, lookup, merchantId],
  );

  const enroll = useCallback(
    /**
     * Enroll method is to initialize a challenge for a user by email and phone in one take
     * Notes: if phone is not required by the auth service for this email - ignore the second argument
     *        if phone is required by the auth service for this email, but it hasn't been passed - throw
     * @param email
     * @param phone
     * @throws
     */
    async (email: string, phone?: string): Promise<void> => {
      // 0. Validate
      if (!merchantId) {
        throw new Error("[useAuth][enroll] Merchant id is missing");
      }

      // 1. Lookup a user by email
      let lookupResponse;
      let emailLookupResponse;
      try {
        emailLookupResponse = await lookupUser(merchantId, email);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const response = (e as AxiosError<ApiResponse<LookupUserResponse>>).response;
          if (response?.status === 404) {
            lookupResponse = { isPhoneRequired: true, transactionId: response?.data?.data?.transactionId };
          } else {
            // Unexpected response code
            e.message = `[useAuth][enroll] Empty email lookup request failed: ${e.message}`;
            throw e;
          }
        } else {
          // Unrecoverable request exception
          (e as Error).message = `[useAuth][enroll] Empty email lookup request failed: ${(e as Error).message}`;
          throw e;
        }
      }

      if (!emailLookupResponse?.data) {
        throw new Error("[useAuth][enroll] Empty email lookup response");
      }
      skipifyEvents.trackLookupUser(email, emailLookupResponse.data.isPhoneRequired);

      lookupResponse = {
        isPhoneRequired: emailLookupResponse.data.isPhoneRequired,
        transactionId: emailLookupResponse.data.transactionId,
      };

      // Break if:
      // - transaction id is missing
      if (!lookupResponse.transactionId) {
        throw new Error(`[useAuth][enroll] Missing transactionId in lookup response`);
      }

      // Break if:
      // - phone is required but not provided by a caller
      if (lookupResponse.isPhoneRequired && !phone) {
        setPartialEnrollmentData({
          isPhoneRequired: lookupResponse.isPhoneRequired,
          email,
          transactionId: lookupResponse.transactionId,
        });

        throw new Error(`[useAuth][enroll] Phone is required but not provided by a caller.`);
      }

      // 2. Lookup a user with a phone if necessary
      if (lookupResponse.isPhoneRequired && phone) {
        let phoneLookupResponse;
        try {
          phoneLookupResponse = await lookupUser(merchantId, undefined, phone, lookupResponse.transactionId);
        } catch (e) {
          // Unrecoverable request exception
          (e as Error).message = `[useAuth][enroll] Phone lookup request failed: ${(e as Error).message}`;
          throw e;
        }

        if (!phoneLookupResponse?.data) {
          throw new Error("[useAuth][enroll] Empty phone lookup response");
        }

        if (phoneLookupResponse.data.isPhoneRequired) {
          throw new Error("[useAuth][enroll] Failed to enrich a lookup with a phone, still required");
        }
      }

      // 3. Accept a challenge (make an /authorize call)
      let authorizeResponse;
      try {
        authorizeResponse = await authorizeService(lookupResponse.transactionId);
        skipifyEvents.track("fe_otp_sent");
      } catch (e) {
        // Unrecoverable request exception
        (e as Error).message = `[useAuth][enroll] Authorize request failed: ${(e as Error).message}`;
        throw e;
      }

      if (authorizeResponse.data) {
        setEnrollmentChallengeInitialization({
          message: authorizeResponse.data.message,
          stepupType: authorizeResponse.data.stepup_type,
          maskedChannel: authorizeResponse.data.maskedChannel || "",
          transactionId: authorizeResponse.data.transactionId,
          email: email,
          canChangePhone: Boolean(lookupResponse.isPhoneRequired),
          destination: authorizeResponse.data.destination,
        });
      } else {
        throw new Error("[useAuth][enroll] Empty authorize response");
      }
    },
    [merchantId, setEnrollmentChallengeInitialization, setPartialEnrollmentData],
  );

  const setUserStatusUnknown = resetIsPhoneRequired;

  // When Max phone error is trigger, we need to restart challenge to retrieve another transactionId
  const onLookUpMaxPhoneErrorResubmit = useCallback(
    async (email: string, phone: string) => {
      try {
        const resp = await lookupUserByEmail(email, true);

        if (resp?.transactionId) {
          const authorizeResp = await acceptChallengeWithPhone(phone, true, resp.transactionId);
          return authorizeResp;
        }
      } catch (err) {
        console.warn("[lookupMaxPhoneLimitReached]", err);
      }
    },
    [acceptChallengeWithPhone, lookupUserByEmail],
  );

  return {
    authorizeUser,
    acceptChallengeWithPhone,
    lookupUserByEmail,
    setUserStatusUnknown,
    abortLookupUserByEmail,
    reAcceptChallenge,
    completeChallenge,
    resetChallenge,
    challengeExists: Boolean(transactionId),
    enroll,
    onLookUpMaxPhoneErrorResubmit,
  };
};

export default useAuth;
