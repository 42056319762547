import { useCallback } from "react";

import { fanoutMessage, fanoutTransactionalMessage } from "./fanoutMessage";
import { LISTENER_IDS, MESSAGE_NAMES, UNINITIALIZED_CALL } from "./parentWindowCommsConstants";
import { enrollmentInfoResponseSchema, returningUserInfoResponseSchema } from "./parentWindowCommsSchemas";
import fp from "@services/fingerprint";
import { SuccessCallbackPayload } from "@models/successCallback";
import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { ShoppersLookupResponse } from "@models/api/apiResponses";
import { Address } from "../../../types/address";

export type EnrollmentData = {
  email: string;
  phone?: string;
  shippingAddress?: {
    address1: string;
    address2?: string;
    city: string;
    firstName: string;
    lastName: string;
    state: string;
    phoneNumber?: string;
    zipCode: string;
  };
  sessionId?: number;
};

export type CarouselComponentSuccessPayload = {
  paymentId: string | null;
  sessionId?: string;
  metadata?: {
    networkType?: string;
    expiryDate?: string;
    lastFour?: string;
  };
  address?: Address;
};

const useParentWindowComms = ({ allowedDomains }: { allowedDomains: string[] }) => {
  const safeTransactionalFanoutMessage = useCallback(
    <T>(
      ...args: Parameters<typeof fanoutTransactionalMessage<T>>
    ): ReturnType<typeof fanoutTransactionalMessage<T>> => {
      if (allowedDomains.length) {
        return fanoutTransactionalMessage(...args);
      }

      return Promise.reject(new Error(UNINITIALIZED_CALL));
    },
    [allowedDomains],
  );

  // ------------- Outbound comms methods -------------

  // Manually inform parents that we are ready to receive messages for a specific listener
  const readyToReceiveMessages = useCallback(
    (id: (typeof LISTENER_IDS)[keyof typeof LISTENER_IDS]) => {
      // deprecated call
      fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.INIT });

      fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.LISTENER_READY, payload: { id } });
    },
    [allowedDomains],
  );

  const getEnrollmentData = useCallback(
    async (timeout = 2000) => {
      return safeTransactionalFanoutMessage<EnrollmentData>(
        allowedDomains,
        { name: MESSAGE_NAMES.GET_ENROLLMENT_INFO },
        MESSAGE_NAMES.ENROLLMENT_INFO_RECEIVED,
        {
          timeout,
          responseSchema: enrollmentInfoResponseSchema,
        },
      );
    },
    [allowedDomains, safeTransactionalFanoutMessage],
  );

  const getReturningUserData = useCallback(
    async (timeout = 2000) => {
      return safeTransactionalFanoutMessage<{ transactionId: string; email: string; isPhoneRequired: boolean }>(
        allowedDomains,
        { name: MESSAGE_NAMES.GET_RETURNING_USER_INFO },
        MESSAGE_NAMES.RETURNING_USER_INFO_RECEIVED,
        {
          timeout,
          responseSchema: returningUserInfoResponseSchema,
        },
      );
    },
    [allowedDomains, safeTransactionalFanoutMessage],
  );

  const hideIframe = useCallback(() => {
    return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.CLOSE_IFRAME, payload: { reload: false } });
  }, [allowedDomains]);

  const closeIframe = useCallback(
    (id?: string) => {
      return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.CLOSE_IFRAME, payload: { reload: true, id } });
    },
    [allowedDomains],
  );

  /**
   * Note: this is used for both legacy (GoCart) merchants and merchants in the new (Skipify) SDK
   * @param {SuccessCallbackPayload} SuccessCallbackPayload onSuccess callback information needed for legacy merchants when an order is complete
   * @param {SuccessCallbackCustomerInfo} SuccessCallbackPayload.customerInfo customer information which includes email, paymentMethod, and phoneNumber
   * @param {SuccessCallbackAddress} SuccessCallbackPayload.billingInfo billing information used for creating the Skipify order.
   * @param {SuccessCallbackAddress} SuccessCallbackPayload.shippingInfo saved shipping info for the order. Will return an empty object for orders that do not include shipping.
   * @param {SuccessCallbackShippingMethod} SuccessCallbackPayload.shippingMethod details about the shipping method and pricing returned from the shipping query. Will return an empty object for orders that do not include shipping
   * @param {number} SuccessCallbackPayload.taxes The taxes associated with the the order, in cents (Example: 500 corresponds to $5)
   * @param {OrderTransactionDetails} SuccessCallbackPayload.transactionDetails Transactional details related to the order
   */
  const orderCompleted = useCallback(
    (orderId: string, successCallbackPayload?: SuccessCallbackPayload) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.ORDER_COMPLETED,
        payload: {
          orderId,
          ...successCallbackPayload,
        },
      });
    },
    [allowedDomains],
  );

  const resizeContainer = useCallback(
    (height: string, id?: string) => {
      return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.RESIZE_CONTAINER, payload: { height, id } });
    },
    [allowedDomains],
  );

  const displayIframe = useCallback(() => {
    return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.DISPLAY_IFRAME });
  }, [allowedDomains]);

  const userEligible = useCallback(() => {
    return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.ENROLLMENT_ELIGIBLE });
  }, [allowedDomains]);

  const lookupError = useCallback(
    (error: Error) => {
      return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.LOOKUP_ERROR, payload: { error } });
    },
    [allowedDomains],
  );

  const createOrderError = useCallback(
    (error: Error) => {
      return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.CREATE_ORDER_ERROR, payload: { error } });
    },
    [allowedDomains],
  );

  const sendDeviceId = useCallback(async () => {
    const { visitorId: deviceId } = await fp.getFingerprint();
    return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.DEVICE_ID, payload: { deviceId } });
  }, [allowedDomains]);

  const sendShopperLookupData = useCallback(
    async (data: ShoppersLookupResponse) => {
      return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.SHOPPERS_LOOKUP_RESPONSE, payload: data });
    },
    [allowedDomains],
  );

  /**
   * Note: this doesn't rely on any state in the useParentWindowComms hook, but the functionality logically fits with the other handlers here.
   * Should only be used by GoCart.
   * @deprecated
   */
  const sendCustomerExists = (e: MessageEvent, customerExists: boolean) => {
    e.ports[0].postMessage({ name: MESSAGE_NAMES.CUSTOMER_EXISTS, payload: { customerExists } });
  };

  /**
   * @description Sends the OID to the Checkout-SDK to make sure that SDK saves the order data for resumable experience
   * There is no current difference with sendOrderId, we just need different names to put it under a feature flag
   */
  const sendResumableOrderId = useCallback(
    async (orderId: string) => {
      return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.RESUMABLE_ORDER_ID, payload: { orderId } });
    },
    [allowedDomains],
  );

  /**
   * @description This sends a message to GoCart SDK letting merchants know when an order was successfully created.
   * This is an optional callback that GoCart merchants can implement.
   * @deprecated
   */
  const sendOrderId = useCallback(
    async (orderId: string) => {
      return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.ORDER_ID, payload: { orderId } });
    },
    [allowedDomains],
  );

  const clearOrder = useCallback(() => {
    return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.CLEAR_ORDER });
  }, [allowedDomains]);

  const askForOrderId = useCallback(async () => {
    return safeTransactionalFanoutMessage(
      allowedDomains,
      {
        name: MESSAGE_NAMES.ASK_FOR_ORDER_ID,
      },
      MESSAGE_NAMES.RECEIVE_ORDER_ID,
    );
  }, [allowedDomains, safeTransactionalFanoutMessage]);

  //send LD flags to parent window
  const sendLdFlags = useCallback(
    async (flags: LDFlagSet) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.SEND_LD_FLAGS,
        payload: { flags },
      });
    },
    [allowedDomains],
  );

  /**
   * @description This sends a message to GoCart SDK letting merchants know when a user closes the checkout before completing an order.
   * This is an optional callback that GoCart merchants can implement.
   * @deprecated Should only be used by GoCart.
   */
  const checkoutExitedBeforeOrderComplete = useCallback(async () => {
    return fanoutMessage(allowedDomains, { name: MESSAGE_NAMES.CHECKOUT_EXITED_BEFORE_ORDER_COMPLETE });
  }, [allowedDomains]);

  /**
   * @description - This sends a message to GoCart SDK when payment could not be completed.
   * This is a required callback that GoCart merchants implement
   * @param errorDetails.orderId - the id of the order that could not be completed
   * @param {string} errorDetails.errorType - The type of error that occurred,
   * but GoCart distinguishes between generic message versus specific messages for CustomerError and MerchantError
   * @deprecated Should only be used by GoCart.
   */
  const sendPaymentErrorOccurred = useCallback(
    async (errorDetails: { orderId: string; errorType?: string }) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.PAYMENT_ERROR_COMPLETE_CHECKOUT,
        payload: errorDetails,
      });
    },
    [allowedDomains],
  );

  /**
   * Send lookup by fingerprint result back to SDK
   * This message is only used for samsung demo (button checkout + device id lookup) at the moment.
   */
  const sendLookupByFingerprintResult = useCallback(
    async (payload: { customerDeviceRecognized: boolean }) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.LOOKUP_BY_FINGERPRINT_RESULT,
        payload,
      });
    },
    [allowedDomains],
  );

  /**
   * Sending successful authentication data back to SDK for Embedded Components flow
   */
  const sendAuthComponentSuccess = useCallback(
    async (payload: { shopperId: string; sessionId: string }) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.AUTH_COMPONENT_SUCCESS,
        payload,
      });
    },
    [allowedDomains],
  );

  /**
   * Sending error data back to SDK when authentication fails for Embedded Components flow
   */
  const sendAuthComponentError = useCallback(
    async (payload: { error: { message: string } }) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.AUTH_COMPONENT_ERROR,
        payload,
      });
    },
    [allowedDomains],
  );

  const sendCarouselComponentSuccess = useCallback(
    async (payload: CarouselComponentSuccessPayload) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.CAROUSEL_COMPONENT_SUCCESS,
        payload,
      });
    },
    [allowedDomains],
  );

  const sendCarouselComponentError = useCallback(
    async (payload: { error: { message: string } }) => {
      return fanoutMessage(allowedDomains, {
        name: MESSAGE_NAMES.CAROUSEL_COMPONENT_ERROR,
        payload,
      });
    },
    [allowedDomains],
  );

  /**
   * Notifying SDK to reset ananlytics session ttl after success auth for analytics session BOW-275
   */
  const sendResetAnalyticsTtl = useCallback(async () => {
    return fanoutMessage(allowedDomains, {
      name: MESSAGE_NAMES.RESET_ANALYTICS_TTL,
    });
  }, [allowedDomains]);

  return {
    askForOrderId,
    getEnrollmentData,
    getReturningUserData,
    closeIframe,
    hideIframe,
    resizeContainer,
    displayIframe,
    userEligible,
    lookupError,
    orderCompleted,
    createOrderError,
    readyToReceiveMessages,
    sendDeviceId,
    clearOrder,
    sendResumableOrderId,
    sendLdFlags,
    sendLookupByFingerprintResult,
    sendShopperLookupData,
    sendAuthComponentSuccess,
    sendAuthComponentError,
    sendCarouselComponentSuccess,
    sendCarouselComponentError,
    sendResetAnalyticsTtl,
    sendCustomerExists,
    sendOrderId,
    checkoutExitedBeforeOrderComplete,
    sendPaymentErrorOccurred,
  };
};

export default useParentWindowComms;
