import { datadogActions } from "@constants/datadogEvents";
import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";

export const encryptValue = async (value: string, merchantId: string) => {
  try {
    const startTime = performance.now();
    const response = await axios.post("/api/encrypt", { value }, { headers: { "x-merchant-id": merchantId } });
    const duration = performance.now() - startTime;
    datadogRum.addAction(datadogActions.ENCRYPT_API_CALL, {
      ms: duration,
    });
    return response.data;
  } catch (error: unknown) {
    console.warn("Unable to encrypt value", error);
    return null;
  }
};

export const decryptValue = async (value: string, merchantId: string) => {
  try {
    const startTime = performance.now();
    const response = await axios.post("/api/decrypt", { value }, { headers: { "x-merchant-id": merchantId } });
    const duration = performance.now() - startTime;
    datadogRum.addAction(datadogActions.DECRYPT_API_CALL, {
      ms: duration,
    });
    return response.data;
  } catch (error: unknown) {
    console.warn("Unable to decrypt value", error);
    return null;
  }
};
