import { createTheme, Theme, TypographyVariantsOptions } from "@mui/material/styles";

/**
 * Helper function to override parts of a given theme
 * This is used in embedded components to override fontSize, fontFamily, colors, etc.
 */

const SERIF_FONT = "'Times New Roman', serif";
const SANS_SERIF_FONT = "Arial, sans-serif";

export default function getModifiedTheme(
  originalTheme: Theme,
  config?: {
    fontSize?: "small" | "medium" | "large";
    fontFamily?: "serif" | "sans-serif" | "default";
    theme?: "light" | "dark";
  },
) {
  // Early return if no modifications needed
  if (
    (!config?.fontSize || config.fontSize === "medium") &&
    (!config?.fontFamily || config.fontFamily === "default") &&
    (!config?.theme || config.theme === "light")
  ) {
    return originalTheme;
  }

  // Font size settings

  let fontSizeSettings: Partial<TypographyVariantsOptions> = {};

  if (config?.fontSize === "large") {
    fontSizeSettings = {
      large: {
        ...originalTheme.typography.large,
        fontSize: "20px",
      },
      medium: {
        ...originalTheme.typography.medium,
        fontSize: "16px",
      },
    };
  } else if (config?.fontSize === "small") {
    fontSizeSettings = {
      large: {
        ...originalTheme.typography.large,
        fontSize: "14px",
      },
      medium: {
        ...originalTheme.typography.medium,
        fontSize: "12px",
      },
    };
  }

  // Font family settings

  const createFontSettings = (originalTheme: Theme, fontFamily: string) => {
    return {
      fontFamily,
      // some build-in variants that require override
      body1: { ...originalTheme.typography.body1, fontFamily },
      button: { ...originalTheme.typography.button, fontFamily },
    };
  };

  let fontFamilySettings: Partial<TypographyVariantsOptions> = {};

  if (config?.fontFamily === "serif") {
    fontFamilySettings = createFontSettings(originalTheme, SERIF_FONT);
  } else if (config?.fontFamily === "sans-serif") {
    fontFamilySettings = createFontSettings(originalTheme, SANS_SERIF_FONT);
  }

  // Color palette settings
  let colorPaletteSettings = {};

  if (config?.theme === "dark") {
    colorPaletteSettings = {
      background: {
        ...originalTheme.palette.background,
        default: "#000000",
        light: "#000000",
        dark: "#FEFEFE",
        paper: "#000000",
      },
      text: {
        ...originalTheme.palette.text,
        primary: "#FEFEFE",
        secondary: "#FEFEFE",
        white: "#1E1E1E",
      },
      primary: {
        ...originalTheme.palette.primary,
        main: "#464646",
        dark: "#FEFEFE",
        light: "#353535",
      },
      secondary: {
        ...originalTheme.palette.secondary,
        light: "#262626",
      },
      error: {
        ...originalTheme.palette.error,
        light: "#601600",
      },
      success: {
        ...originalTheme.palette.success,
        light: "#124600",
      },
      link: {
        ...originalTheme.palette.link,
        main: "#8AD6D2",
      },
      brand: {
        ...originalTheme.palette.brand,
        light: "#E3DBCC",
      },

      // some components don't match global dark theme palette and need to be overridden
      // this section is not defined in regular diagonal palette, will fall back to regular color in light theme

      darkThemeOverride: {
        baseCardBg: "#1E1E1E", // background color for carousel card
        cardActionBtnBg: "#1E1E1E", // background color for card action button
        cardActionBtnBorderColor: "#E0E0E0", // border color for card action button
        cardArtBg: "#353535", // background color for carousel card art
        newCardBg: "#474747", // background color for "add new card" card
        unlinkedCardBg: "#474747", // background color for unlinked carousel card
      },
    };
  }

  return createTheme({
    ...originalTheme,
    typography: {
      ...originalTheme.typography,
      ...fontSizeSettings,
      ...fontFamilySettings,
    },
    palette: {
      ...originalTheme.palette,
      ...colorPaletteSettings,
    },
  });
}
