import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";

import { getClaims } from "@services/auth";
import { Claims } from "@models/claims";
import { inDevTestLocal } from "@utils/inDevTestLocal";

type ClaimsStore = {
  claims?: Claims;
  claimsLoading: boolean;
  claimsError: string;
  getClaims: () => Promise<void>;
  reset: () => void;
};

const claimsStore: StateCreator<ClaimsStore, [["zustand/devtools", ClaimsStore]]> = (set) => ({
  claimsLoading: false,
  claimsError: "",

  async getClaims() {
    set({ claimsLoading: true });
    try {
      const res = await getClaims();
      set({ claims: res.data });
      set({ claimsError: "" });
    } catch (e) {
      console.error("[authStore] failed to get claims");
      set({ claimsError: "There was an error fetching or updating your information. Please try again later." });
    } finally {
      set({ claimsLoading: false });
    }
  },

  reset() {
    set(
      () => {
        return {
          claimsLoading: false,
          claimsError: "",
        };
      },
      false,
      "reset",
    );
  },
});

const useClaimsStore = create<ClaimsStore>()(devtools(claimsStore, { enabled: inDevTestLocal, name: "claimsStore" }));

export default useClaimsStore;
