import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { jwtDecode } from "jwt-decode";

import { Destination, DestinationAlternative, ShoppersLookupResponse } from "@models/api/apiResponses";
import skipifyEvents from "@services/skipifyEvents";
import { inDevTestLocal } from "@utils/inDevTestLocal";
import { ChallengeStatus } from "@hooks/useAuth";
import { AxiosError } from "axios";
import { TTLStorage } from "@utils/ttlStorage";
import { SKIPIFY_JWT_CONST } from "@hooks/useTokenRefresh";
import { encryptValue } from "@utils/encryptDecrypt";
import { ecAcceptChallenge, ecCompleteChallenge } from "@app/api/identity-cloud/actions";
import { getSkipifyHeaders } from "@services/utils/interceptWithSkipifyHeaders";
import { getFingerprintHeaders } from "@services/utils/interceptWithFingerprint";

type DisplayMode = "embedded" | "overlay";
type FontSize = "small" | "medium" | "large";
type FontFamily = "serif" | "sans-serif" | "default";
type Theme = "light" | "dark";

export type AuthOptionsData = {
  phone: string;
  sendOtp: boolean;
  displayMode?: DisplayMode;
  config?: {
    fontSize?: FontSize;
    fontFamily?: FontFamily;
    theme?: Theme;
  };
};

export type AuthResultsData = {
  shopperId: string;
  sessionId: string;
};

type EmbeddedComponentStore = {
  // Authentication
  lookupData?: ShoppersLookupResponse;
  setLookupData: (payload: ShoppersLookupResponse) => void;
  setAuthResultsData: (payload: AuthResultsData) => void;
  authOptionsData?: AuthOptionsData;
  setAuthOptionsData: (payload: AuthOptionsData) => void;
  acceptChallenge: (merchantId: string, challengeId: string, payload?: Record<string, unknown>) => Promise<void>;
  completeChallenge: (
    merchantId: string,
    challengeId: string,
    value: string,
    encryptJwt: boolean,
  ) => Promise<ChallengeStatus>;
  maskedChannel?: string;
  destination?: Destination;
  destinationAlternatives?: DestinationAlternative[];
  metadata?: object;
  jti?: string;
  shopperId?: string;
  remainingAttempts?: number;
  shopperPhoneInput?: string;
  setShopperPhoneInput: (payload: string) => void;
  orderTotal: number;
  setOrderTotal: (n: number) => void;
  displayMode?: DisplayMode;
  fontSize: FontSize;
  fontFamily: FontFamily;
  setFontSize: (size: FontSize) => void;
  setFontFamily: (family: FontFamily) => void;
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const embeddedComponentStore: StateCreator<EmbeddedComponentStore, [["zustand/devtools", EmbeddedComponentStore]]> = (
  set,
  getState,
) => ({
  orderTotal: 0,
  fontSize: "medium",
  fontFamily: "default",
  theme: "light",
  setFontSize(size: FontSize) {
    set({ fontSize: size }, false, "setFontSize");
  },
  setFontFamily(family: FontFamily) {
    set({ fontFamily: family }, false, "setFontFamily");
  },
  setTheme(theme: Theme) {
    set({ theme }, false, "setTheme");
  },
  setOrderTotal(n: number) {
    set({ orderTotal: n }, false, "setOrderTotal");
  },
  setAuthResultsData(payload: AuthResultsData) {
    set({ shopperId: payload.shopperId, jti: payload.sessionId }, false, "setAuthResultsData");
  },
  setLookupData(data: ShoppersLookupResponse) {
    set({ lookupData: data }, false, "setLookupData");
    if (data.flags.phoneRequired) {
      skipifyEvents.track("fe_pre_otp_phone_requested");
    }
  },

  setAuthOptionsData(payload: AuthOptionsData) {
    set(
      {
        authOptionsData: payload,
        displayMode: payload.displayMode,
        fontSize: payload.config?.fontSize ?? "medium",
        fontFamily: payload.config?.fontFamily ?? "default",
        theme: payload.config?.theme ?? "light",
      },
      false,
      "setAuthOptionsData",
    );
  },

  setShopperPhoneInput(phone: string) {
    set({ shopperPhoneInput: phone }, false, "setShopperPhoneInput");
  },

  async acceptChallenge(merchantId: string, challengeId: string, payload?: Record<string, unknown>) {
    try {
      const response = await ecAcceptChallenge(merchantId, challengeId, payload, {
        ...getSkipifyHeaders(),
        ...(await getFingerprintHeaders()),
      });

      skipifyEvents.track("fe_otp_sent");
      set(
        {
          maskedChannel: response.maskedChannel,
          destination: response.destination,
          destinationAlternatives: response.destinationAlternatives,
          metadata: response.metadata,
        },
        false,
        "acceptChallenge",
      );
    } catch (e) {
      console.warn(e);
      throw e;
    }
  },

  async completeChallenge(merchantId: string, challengeId: string, value: string, encryptJwt: boolean) {
    try {
      const response = await ecCompleteChallenge(merchantId, challengeId, value, {
        ...getSkipifyHeaders(),
        ...(await getFingerprintHeaders()),
      });
      if (response.jwt) {
        // Save jwt in local storage with TTL of 30min
        const ttlStorage = new TTLStorage();
        if (encryptJwt) {
          // Encrypt JWT before storage
          try {
            const encrpytedJwt = await encryptValue(response.jwt, merchantId);
            ttlStorage.setItem(
              SKIPIFY_JWT_CONST.LOCAL_STORAGE_KEY,
              { value: encrpytedJwt.value, e: true },
              SKIPIFY_JWT_CONST.TTL,
            );
          } catch (e) {
            console.warn("[completeChallenge]: Unable to encrypt JWT", e);
            ttlStorage.setItem(
              SKIPIFY_JWT_CONST.LOCAL_STORAGE_KEY,
              { value: response.jwt, e: false },
              SKIPIFY_JWT_CONST.TTL,
            );
          }
        } else {
          ttlStorage.setItem(
            SKIPIFY_JWT_CONST.LOCAL_STORAGE_KEY,
            { value: response.jwt, e: false },
            SKIPIFY_JWT_CONST.TTL,
          );
        }
        const { jti, sub: shopperId } = jwtDecode(response.jwt);
        set(
          {
            jti,
            shopperId,
          },
          false,
          "completeChallenge",
        );
        skipifyEvents.track("fe_otp_success");
        skipifyEvents.track("fe_auth", {
          status: "Success",
          auth_method: `OTP_${getState().destination?.toUpperCase()}`,
        });
        return ChallengeStatus.COMPLETED;
      } else {
        skipifyEvents.track("fe_otp_fail");
        return ChallengeStatus.FAILED;
      }
    } catch (e) {
      console.log(e);
      if (e instanceof AxiosError) {
        //If response is 429 need to display too many wrong attempts
        if (e?.response?.status === 429) {
          skipifyEvents.track("fe_otp_fail");
          return ChallengeStatus.TOO_MANY_ATTEMPTS;
        }

        if (e?.response?.status === 404) {
          return ChallengeStatus.NOT_FOUND;
        }

        if (e?.response?.status === 422) {
          if (e.response?.data?.data?.remainingAttempts) {
            set({ remainingAttempts: e.response?.data?.data?.remainingAttempts });
          }
          skipifyEvents.track("fe_otp_fail");
        }
        skipifyEvents.track("fe_auth", { status: "Fail", auth_method: `OTP_${getState().destination?.toUpperCase()}` });
      }

      return ChallengeStatus.ERROR;
    }
  },
});

const useEmbeddedComponentStore = create<EmbeddedComponentStore>()(
  devtools(embeddedComponentStore, { enabled: inDevTestLocal, name: "embeddedComponentStore" }),
);

export default useEmbeddedComponentStore;
