import { TypographyOptions } from "@mui/material/styles/createTypography";

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  extraBold: 700,
};

const typography: TypographyOptions = {
  fontFamily: "Poppins, sans-serif",
  fontWeightLight: fontWeights.light,
  fontWeightRegular: fontWeights.regular,
  fontWeightMedium: fontWeights.medium,
  fontWeightBold: fontWeights.bold,

  huge: {
    fontSize: "32px",
    letterSpacing: -1.5,
    fontWeight: fontWeights.bold,
    lineHeight: "36px",
  },
  xxl: {
    fontSize: "24px",
    letterSpacing: -1,
    fontWeight: fontWeights.bold,
    lineHeight: "28px",
  },
  xl: {
    fontSize: "20px",
    letterSpacing: -0.5,
    fontWeight: fontWeights.regular, // weight reset for browser default h3
  },
  large: {
    fontSize: "16px",
  },
  medium: {
    fontSize: "14px",
    lineHeight: "18px",
  },
  small: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  xs: {
    fontSize: "11px",
    lineHeight: "16px",
  },
  micro: {
    fontSize: "10px",
  },
};

export default typography;
