"use client";

import { useEffect, useCallback } from "react";

import { getTokenWithoutInterceptor } from "@services/user";
import useClaimsStore from "@stores/claimsStore";
import inMemoryStorage, { IN_MEMORY_STORAGE_KEYS } from "@utils/inMemoryStorage";
import useAuthenticationStatusStore, { AuthenticationStatus } from "@stores/authenticationStatusStore";
import { TTLStorage } from "@utils/ttlStorage";
import { decryptValue } from "@utils/encryptDecrypt";
import useMerchantConfigStore from "@stores/merchantConfigStore";

export const SKIPIFY_JWT_CONST = {
  LOCAL_STORAGE_KEY: "sjwt",
  TTL: 1800000, // 30 min,
};

const useTokenRefresh = (): {
  status: AuthenticationStatus;
  evaluate: () => Promise<void>;
  pullClaims: () => Promise<void>;
} => {
  const getClaims = useClaimsStore((state) => state.getClaims);
  const claims = useClaimsStore((state) => state.claims);

  const status = useAuthenticationStatusStore((s) => s.status);
  const setStatus = useAuthenticationStatusStore((s) => s.setStatus);
  const [merchantId] = useMerchantConfigStore((state) => [state.merchantId]);

  const pullClaims = useCallback(async () => {
    try {
      await getClaims();
      setStatus(AuthenticationStatus.AUTHENTICATED);
    } catch (e) {
      console.warn("[useTokenRefresh] Failed to get claims", e);
      setStatus(AuthenticationStatus.UNAUTHENTICATED);
    }
  }, [getClaims, setStatus]);

  const fetchTokenFromCookie = useCallback(async () => {
    // Try getting token from cookie
    try {
      const token = (await getTokenWithoutInterceptor()).data;
      if (token) {
        inMemoryStorage.setItem(IN_MEMORY_STORAGE_KEYS.SESSION, token);
        setStatus(AuthenticationStatus.CLAIMS_PENDING);
      }
    } catch (err) {
      setStatus(AuthenticationStatus.UNAUTHENTICATED);
      console.warn("[useTokenRefresh] Error fetching session", err);
    }
  }, [setStatus]);

  const evaluate = useCallback(async () => {
    setStatus(AuthenticationStatus.WAITING_FOR_STATUS);
    // Fetch from in memory storage
    if (inMemoryStorage.getItem(IN_MEMORY_STORAGE_KEYS.SESSION)) {
      if (!claims?.exp) {
        return setStatus(AuthenticationStatus.CLAIMS_PENDING);
      }

      return setStatus(AuthenticationStatus.AUTHENTICATED);
    }

    // Fetch from local store if exists
    const jwtFromLocalStorage = new TTLStorage().getItem<{ value: string; e: boolean }>(
      SKIPIFY_JWT_CONST.LOCAL_STORAGE_KEY,
    );
    if (jwtFromLocalStorage && merchantId) {
      // If we have a valid value from local storage - use it
      if (jwtFromLocalStorage.e) {
        // If the value is encrypted we need to decrypt it
        // Decrpyt the value
        try {
          const decrpytedJwt = await decryptValue(jwtFromLocalStorage.value, merchantId);
          if (decrpytedJwt.value) {
            // Set jwt if we successfully decrypt it
            inMemoryStorage.setItem(IN_MEMORY_STORAGE_KEYS.SESSION, decrpytedJwt.value);
            return setStatus(AuthenticationStatus.CLAIMS_PENDING);
          }
        } catch (e) {
          console.warn("[useTokenRefresh] Error decrypting jwt", e);
        }
      } else {
        // If value is not encrypted then set it
        inMemoryStorage.setItem(IN_MEMORY_STORAGE_KEYS.SESSION, jwtFromLocalStorage.value);
        return setStatus(AuthenticationStatus.CLAIMS_PENDING);
      }
    }

    // Try getting token from cookie
    await fetchTokenFromCookie();
  }, [claims?.exp, setStatus, merchantId, fetchTokenFromCookie]);

  // Pulling claims if requested
  useEffect(() => {
    if (status === AuthenticationStatus.CLAIMS_PENDING) {
      pullClaims();
    }
  }, [pullClaims, status]);

  return { evaluate, status, pullClaims };
};

export default useTokenRefresh;
